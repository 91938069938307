
import errorSnackbar from "@/store/modules/errorSnackbar";
import spinnerModule from "@/store/modules/spinnerModule";
import { mdiBankTransfer } from "@mdi/js";
import { ApiBancos, ApiErrorResponse, ApiList, AxiosHttpClient, getUrl } from "apd.apiconnectors";
import { Banorte } from "apd.models";
import { format } from "date-fns/esm";
import Vue from "vue";
import { DataTableHeader } from "vuetify";

export default Vue.extend({
  name: "CortesTarjeta",
  components: {
    selectorAfiliaciones: () => import("@/components/selectores/afiliaciones.vue"),
    selectorFechas: () => import("@/components/selectores/fechas.vue"),
    boton: () => import("@/components/botones/boton.vue"),
    dialogReportes: () => import("@/components/dialogReportes.vue"),
  },
  filters: {
    toMoney(value: number): string {
      if (typeof value !== "number") {
        return value;
      }
      return "$" + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    toDate(value: string): string {
      if (value) {
        return format(Date.parse(value), "MM/dd/yy HH:mm:ss");
      } else {
        return "";
      }
    },
  },
  data: () => ({
    icons: {
      reporte: mdiBankTransfer,
    } as Record<string, string>,
    fecha: "" as string,
    apiBancos: null as ApiBancos | null,
    cortes: [] as Banorte.Payworks2.Corte[],
    montoTotal: 0 as number,
    afiliaciones: "" as string | string[] | null,
    expanded: [] as Banorte.Payworks2.Corte[],
    singleExpand: false,
    headers: [
      {
        text: "Fecha",
        value: "fecha",
        align: "center",
        sortable: true,
      },
      {
        text: "Afiliación",
        value: "afiliacion",
        align: "center",
        sortable: true,
      },
      {
        text: "Comercio",
        value: "comercio",
        align: "center",
        sortable: true,
      },
      {
        text: "Tipo Afiliación",
        value: "tipoAfiliacion",
        align: "center",
        sortable: true,
      },
      {
        text: "Cantidad Transacciones",
        value: "numeroTransacciones",
        align: "center",
        sortable: true,
      },
      {
        text: "Total",
        value: "total",
        align: "right",
        sortable: true,
      },
      { text: "", value: "data-table-expand", sortable: false },
    ] as DataTableHeader[],
    headersDetalle: [
      {
        text: "Fecha",
        value: "fecha",
        align: "center",
        sortable: true,
        class: "grey darken-3 white--text",
      },
      {
        text: "Tarjeta",
        value: "tarjeta",
        align: "center",
        sortable: true,
        class: "grey darken-3 white--text",
      },
      {
        text: "Banco",
        value: "banco",
        align: "center",
        sortable: true,
        class: "grey darken-3 white--text",
      },
      {
        text: "Tipo",
        value: "tipo",
        align: "center",
        sortable: true,
        class: "grey darken-3 white--text",
      },
      {
        text: "Marca",
        value: "marca",
        align: "center",
        sortable: true,
        class: "grey darken-3 white--text",
      },
      {
        text: "Numero de control",
        value: "controlNumber",
        align: "center",
        sortable: true,
        class: "grey darken-3 white--text",
      },
      {
        text: "Referencia",
        value: "referencia1",
        align: "center",
        sortable: true,
        class: "grey darken-3 white--text",
      },
      {
        text: "Autorizacion",
        value: "autorizacion",
        align: "center",
        sortable: true,
        class: "grey darken-3 white--text",
      },
      {
        text: "Importe",
        value: "importe",
        align: "center",
        sortable: true,
        class: "grey darken-3 white--text",
      },
    ] as DataTableHeader[],
    vistaReporte: ["Detallado", "Simple"],
    vista: "Detallado",
  }),
  computed: {
    titulo(): string {
      return "Reporte corte tarjeta día " + this.fecha;
    },
    disableAfiliaciones(): boolean {
      return this.afiliaciones && this.afiliaciones.length > 0 ? false : true;
    },
    multiple(): boolean {
      return this.vista == "Simple" ? true : false;
    },
    fechaSeparada() {
      return this.fecha.split("-").map(function (item) {
        return parseInt(item, 10);
      });
    },
    disableExport(): boolean {
      return !this.multiple && !this.disableAfiliaciones ? false : true;
    },
  },
  created() {
    const client = new AxiosHttpClient(getUrl(ApiList.Bancos, this.$store.getters.appMode));
    client.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
    this.apiBancos = new ApiBancos(client);
  },
  methods: {
    changeAfiliaciones(data: string[] | null) {
      this.afiliaciones = data;
    },
    async Visualizar(dialog: boolean, show: (value: boolean) => void) {
      spinnerModule.Show();

      this.cortes = [];
      const fechaSeparada = this.fecha.split("-").map(function (item) {
        return parseInt(item, 10);
      });
      if (this.afiliaciones && this.apiBancos) {
        if (this.multiple) {
          for (const afiliacion of this.afiliaciones) {
            try {
              this.cortes.push(
                (await this.apiBancos.GetCorteAfiliacion(
                  afiliacion,
                  fechaSeparada[2],
                  fechaSeparada[1],
                  fechaSeparada[0],
                )) as Banorte.Payworks2.Corte,
              );
            } catch {
              //
            }
          }
          this.expanded = [];
        } else {
          try {
            this.cortes.push(
              (await this.apiBancos.GetCorteDetalladoAfiliacion(
                this.afiliaciones.toString(),
                fechaSeparada[2],
                fechaSeparada[1],
                fechaSeparada[0],
              )) as Banorte.Payworks2.Corte,
            );
          } catch (e) {
            const err: ApiErrorResponse = e as ApiErrorResponse;
            errorSnackbar.Show(err.apiError.mensajeUsuario);
          }

          this.expanded = [this.cortes[0]];
        }
      }

      this.montoTotal = this.cortes.reduce((c, i) => c + Number(i.total), 0);

      if (this.cortes && this.cortes.length > 0) {
        show(!dialog);
      } else {
        errorSnackbar.Show("No se encontraron datos.");
      }
      spinnerModule.Hide();
    },
    async exportar() {
      spinnerModule.Show();
      if (this.apiBancos && this.afiliaciones) {
        try {
          const data = await this.apiBancos.GenerarExcelCorteTC(
            this.afiliaciones.toString(),
            this.fechaSeparada[2],
            this.fechaSeparada[1],
            this.fechaSeparada[0],
          );
          /*const base64 = data as string;
          const linkSource = "data:application/xlsx;base64," + base64;
          const downloadLink = document.createElement("a");
          const fileName = "CorteTC " + this.afiliaciones?.toString() + "-" + this.fecha + ".xlsx";

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();*/

          const blob = this.b64toBlob(data.toString(), "application/vnd.ms-excel");
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "CorteTC.xlsx");
          document.body.appendChild(link);
          link.click();
        } catch (e) {
          const err: ApiErrorResponse = e as ApiErrorResponse;
          errorSnackbar.Show(err.apiError.mensajeUsuario);
        }
      } else {
        errorSnackbar.Show("Ocurrio un error al exportar los datos.");
      }
      spinnerModule.Hide();
    },
    b64toBlob(b64Data: string, contentType = "", sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
  },
});
